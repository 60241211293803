import { TabPanel } from '@chakra-ui/react';

export default function MobileTabPanel({ children, ...props }: { children: any; [x: string]: any }) {
    return (
        <TabPanel
            borderRadius="base"
            h="calc(100vh - 12rem)"
            bgColor="card.100"
            m="0.25rem"
            overflowY="scroll"
            p="0rem"
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
            {...props}
        >
            {children}
        </TabPanel>
    );
}
