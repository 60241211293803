import { TopTradersResponseItem } from '@derivadex/types';
import { AppState } from 'store/slices';

export function getLeaderboardData(state: AppState): TopTradersResponseItem[] {
    if (state.leaderboard.leaderboardData.currentPage === 0) {
        return [];
    }
    return state.leaderboard.leaderboardData.cache[state.leaderboard.leaderboardData.currentPage - 1] ?? [];
}

export function getLeaderboardDataCache(state: AppState) {
    return state.leaderboard.leaderboardData.cache;
}

export function getLeaderboardDataCurrentPage(state: AppState) {
    return state.leaderboard.leaderboardData.currentPage;
}

export function getLeaderboardDataCursor(state: AppState): number | null {
    return state.leaderboard.leaderboardData.nextCursor;
}
