import { BigNumber } from '@0x/utils';
import { Flex, IconButton, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { getEtherscanLink, getFrontendLogger } from '@derivadex/utils';
import { createColumnHelper } from '@tanstack/react-table';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { format } from 'date-fns';
import { addHexPrefix } from 'ethereumjs-util';
import { useTranslation } from 'react-i18next';
import getIcons from 'theme/icons';
import { shortenAddress } from 'utils/number_utils';
import { useAccount } from 'wagmi';

export default function LeaderboardTable({
    isDesktopView,
    data,
    paginationData,
    limit,
}: {
    isDesktopView: boolean;
    data: any[];
    paginationData: { isEnabled: boolean; currentPage: number; prevPage?: () => void; nextPage?: () => void };
    limit: number;
}) {
    const { chain } = useAccount();
    const { t } = useTranslation();
    const { colors } = useTheme();

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor((row, index) => index, {
            cell: (info) => <Text>{limit * (paginationData?.currentPage - 1) + info.getValue() + 1}</Text>,
            header: t('rank'),
        }),
        columnHelper.accessor('trader', {
            cell: (info) => <Text>{shortenAddress(addHexPrefix(info.getValue().slice(2)))}</Text>,
            header: t('trader'),
        }),
        columnHelper.accessor('accountValue', {
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('accountValue'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('volume', {
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('volume'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('realizedPnl', {
            cell: (info) =>
                info.getValue() !== null ? (
                    <NumberFormatMinimumValue value={info.getValue()} useCurrencyPrefix={true} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('realizedPnl'),
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <>
                    <Flex>
                        <ExplorerButton
                            label={t('accountExplorerButtonDescription')}
                            url={`account/${addHexPrefix(info.getValue().trader?.toLowerCase().slice(2))}`}
                        />
                    </Flex>
                </>
            ),
            header: 'Actions',
        }),
    ];

    return (
        <div className="exchange-leaderboard">
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data}
                emptyText={t('noData')}
                sortBy={[]}
                paginationData={paginationData}
            />
        </div>
    );
}
