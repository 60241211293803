import { Flex, Icon, Tab, Text } from '@chakra-ui/react';

export default function MobileTab({
    icon,
    text,
    index,
    onClick,
}: {
    icon: any;
    text: string;
    index: number;
    onClick: () => void;
}) {
    return (
        <Tab
            _focus={{ boxShadow: 'none' }}
            _selected={{
                color: 'white',
                border: 'solid white',
                borderWidth: '0 0 2px',
                margin: '0 0 -2px',
            }}
            onClick={onClick}
        >
            <Flex direction="column" justify="center" align="center">
                <Icon as={icon} />
                <Text fontWeight="700" fontSize="0.75rem">
                    {text}
                </Text>
            </Flex>
        </Tab>
    );
}
