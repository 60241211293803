import { ActionType } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiAggregationsUrl, getStatsApiUrl } from 'store/config/selectors';
import {
    buildUrl,
    FetchDataState,
    makeRequest,
    parseBalanceResponse,
    parseTopTradersResponse,
} from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import { getLeaderboardDataCache, getLeaderboardDataCurrentPage, getLeaderboardDataCursor } from './selectors';
import { FETCH_LEADERBOARD_DATA, SET_LEADERBOARD_CURRENT_PAGE, SET_LEADERBOARD_DATA } from './slice';

function* handleFetchLeaderboardData(action: ReturnType<typeof FETCH_LEADERBOARD_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getLeaderboardDataCache);
        const currentPage = yield* select(getLeaderboardDataCurrentPage);
        const cursor = yield* select(getLeaderboardDataCursor);
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);

        if (action.payload === FetchDataState.NextPage) {
            const devUrl = 'https://frontend.derivadex.io/stats/api/v1/aggregations';
            const statsApiUrl = yield* select(getStatsApiAggregationsUrl);
            const url = buildUrl(/*statsApiUrl*/ devUrl, undefined, 'traders', {
                limit: 10,
                cursor: cursor !== null ? cursor : undefined,
            });
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseTopTradersResponse, response);

            getFrontendLogger().log('leaderboard parsed response', parsedResponse);

            yield* putResolve(SET_LEADERBOARD_DATA(parsedResponse));
            yield* putResolve(SET_LEADERBOARD_CURRENT_PAGE(currentPage + 1));
        } else {
            yield* putResolve(SET_LEADERBOARD_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception while fetching leaderboard data', getErrorMessage(error));
    }
}

export function* watchLeaderboardData() {
    yield* takeLatest(ActionType.FETCH_LEADERBOARD_DATA, handleFetchLeaderboardData);
}

/**
 * The leaderboard saga layer is responsable for handling side effects for the leaderboard page
 */
export const leaderboardSaga = function* root() {
    yield* all([fork(watchLeaderboardData)]);
};
