import {
    Box,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
import { TopTradersResponseItem } from '@derivadex/types';
import LeaderboardTable from 'components/Leadboard/LeaderboardTable';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderboardData, getLeaderboardDataCurrentPage } from 'store/leaderboard/selectors';
import { FETCH_LEADERBOARD_DATA } from 'store/leaderboard/slice';
import { getProfileDetails } from 'store/profile/selectors';
import { FetchDataState } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { UIViewState, UPDATE_CURRENT_VIEW } from 'store/ui/slice';
import { getEthAddress } from 'store/web3/selectors';

import Layout from '../components/Layout';

interface LeaderboardRow {
    traderAddress: string;
    accountValue: string;
    roi: string;
    pnl: string;
    volume: string;
}

export default function Leaderboard() {
    const isDesktopView = useScreenSize();
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);

    // const currentPage = 0;
    const [leaderboard, setLeaderboard] = useState<LeaderboardRow[]>([]);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        dispatch(UPDATE_CURRENT_VIEW({ view: UIViewState.LEADERBOARD }));
    }, []);

    useEffect(() => {
        fetchLeaderboardWithSearch(search);
    }, [search]);

    useEffect(() => {
        dispatch(FETCH_LEADERBOARD_DATA(FetchDataState.NextPage));
    }, []);

    const data: TopTradersResponseItem[] | undefined = useSelector(getLeaderboardData);
    console.log('get leader data', data);
    const currentPage: number = useSelector(getLeaderboardDataCurrentPage);
    const fetchLeaderboardWithSearch = async (searchTerm: string) => {};

    const nextPage = () => {
        dispatch(FETCH_LEADERBOARD_DATA(FetchDataState.NextPage));
    };

    const prevPage = () => {
        dispatch(FETCH_LEADERBOARD_DATA(FetchDataState.PrevPage));
    };

    return (
        <Flex direction={'column'} py="3rem" px="5rem">
            <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Leaderboard</Heading>
            <Box p="0.5rem" pt="3rem" width={'90%'} height={'80%'}>
                <LeaderboardTable
                    isDesktopView={true}
                    data={data}
                    paginationData={{ isEnabled: true, currentPage, nextPage, prevPage }}
                    limit={10}
                ></LeaderboardTable>
            </Box>
        </Flex>
    );
}
