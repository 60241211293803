import { ActionType, AggregationPeriod, PaginatedTopTradersResponse, TopTradersResponseItem } from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchDataState } from 'store/requestUtils';
import { createAction } from 'typesafe-actions';

export type LeaderboardState = {
    aggregationPeriod: AggregationPeriod;
    leaderboardData: {
        cache: TopTradersResponseItem[][];
        nextCursor: number | null;
        currentPage: number;
    };
};

export const initialLeaderboardState: LeaderboardState = {
    aggregationPeriod: AggregationPeriod.Day,
    leaderboardData: { cache: [], nextCursor: null, currentPage: 0 },
};

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: initialLeaderboardState,
    reducers: {
        SET_LEADERBOARD_DATA: (state, action: PayloadAction<PaginatedTopTradersResponse>) => {
            state.leaderboardData.cache.push(action.payload.traders);
            state.leaderboardData.nextCursor = action.payload.nextCursor;
        },
        SET_LEADERBOARD_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.leaderboardData.currentPage = action.payload;
        },
    },
});

export const { SET_LEADERBOARD_DATA, SET_LEADERBOARD_CURRENT_PAGE } = leaderboardSlice.actions;

export const FETCH_LEADERBOARD_DATA = createAction(ActionType.FETCH_LEADERBOARD_DATA)<FetchDataState>();
